import React, { useEffect, useState } from "react"
import UseDocumentTitle from "../utils/useDocumentTitle"
import Layout from "../containers/Layout"
import { Link } from "react-router-dom"
import { SetLocalStorage } from "../services/LocalStorage"
import { fetchData } from "../services/fetching"
import { useRecoilValue } from "recoil"
import { authAtom } from "../atom/auth"

const PricingData = [
  {
    title: "Basic",
    plan: "Free",
    features: [
      "Up to 2 GB of cloud storage of DICOM files",
      "3 Months maximum storage duration",
      "Unlimited number of monthly shares",
      "Anonymization of up to 100 studies",
      "Chat, Add comment and Attach file to studies of up to 100 studies",
      "Downloads up to 100 studies"
    ]
  },
  {
    title: "Premium",
    plan: "£20",
    vat: true,
    features: [
      "Up to 30 GB of cloud storage of DICOM files",
      "Unlimited maximum storage duration",
      "Unlimited number of monthly shares",
      "Anonymization of up to 400 studies",
      "Chat, Add comment and Attach file to studies of up to 400 studies",
      "Downloads up to 400 studies"
    ]
  },
  {
    title: "Professional",
    plan: "£50",
    vat: true,
    features: [
      "Up to 100 GB of cloud storage of DICOM files",
      "Unlimited maximum storage duration",
      "Unlimited number of monthly shares",
      "Anonymization of up to 1000 studies",
      "Chat, Add comment and Attach file to studies of up to 1000 studies",
      "Downloads up to 1000 studies"
    ]
  },
  {
    title: "Business",
    plan: "Contact us",
    features: [
      "More than 100 GB of cloud storage of DICOM files",
      "Customized maximum storage duration",
      "Customized number of monthly shares",
      "Anonymization of up to customizable number of studies",
      "Chat, Add comment and Attach file to studies of up to customizable number of studies",
      "Downloads up to customizable number of  studies"
    ]
  }
]
export default function Pricing() {
  UseDocumentTitle("Pricing", true)
  const [products, setProducts] = useState([])
  const userAuth = useRecoilValue(authAtom)
  useEffect(() => {
    const getProducts = () => {
      fetchData("GET", "/payments/subscriptions")
        .then((resp) => {
          setProducts(resp.data.data)
        })
        .catch((err) => {
          console.log(err)
          console.error("problem getting products list")
        })
    }
    getProducts()
  }, [])
  return (
    <Layout>
      <section className='max-w-6xl p-5 mx-auto'>
        <h1 className='text-dicom-on-surface-100 font-bold text-lg'>Explore plans and pricing</h1>

        <div className=' grid-cols-5 mt-4 hidden md:grid'>
          <>
            <div className=''></div>

            <div className=' border-r-2  border-gray-300 px-3'>
              <div className='flex flex-col justify-between items-center space-y-5'>
                <h3 className='text-dicom-text-100 font-semibold'>Basic</h3>
                <span className=' text-3xl border-l-dicom-on-surface-100 font-bold'>Free</span>
              </div>
            </div>
            <div className='  border-r-2  border-gray-300  px-3'>
              <div className='flex flex-col justify-between items-center space-y-5'>
                <h3 className='text-dicom-text-100 font-semibold'>Premium</h3>
                <div className='w-full flex flex-col justify-center items-center -space-y-1'>
                  <span className=' text-3xl border-l-dicom-on-surface-100 font-bold text-center'>£20</span>
                  <span className='block font-semibold text-base text-dicom-text-100'>
                    Per Month <span className='text-dicom-on-surface-100 text-xs'>+ VAT</span>
                  </span>
                </div>
              </div>
            </div>
            <div className='  border-r-2  border-gray-300 px-3 '>
              <div className='flex flex-col justify-between items-center space-y-5'>
                <h3 className='text-dicom-text-100 font-semibold'>Professional</h3>
                <div className='w-full flex flex-col justify-center items-center -space-y-1'>
                  <span className=' text-3xl border-l-dicom-on-surface-100 font-bold text-center'>£50</span>
                  <span className='block font-semibold text-base text-dicom-text-100'>
                    Per Month <span className='text-dicom-on-surface-100 text-xs'>+ VAT</span>
                  </span>
                </div>
              </div>
            </div>

            <div className='  '>
              <div className='flex flex-col justify-between items-center space-y-5'>
                <h3 className='text-dicom-text-100 font-semibold'>Business</h3>
                <div className='w-full flex flex-col justify-center items-center -space-y-1'>
                  <span className=' text-3xl border-l-dicom-on-surface-100 font-bold text-center'>Contact us</span>
                </div>
              </div>
            </div>
          </>
          <>
            <div className=' py-5'>
              <span className='block text-dicom-primary-100 text-sm font-semibold'>Features</span>
            </div>
            <div className=' py-5 border-r-2  border-gray-300 px-3'>
              <Link
                to='/auth/join-us'
                className='block text-dicom-primary-100 text-sm border border-dicom-primary-100 rounded-lg py-2 w-full text-center'>
                Get started
              </Link>
            </div>
            <div className=' py-5 border-r-2  border-gray-300 px-3'>
              {/* <Link
                to={`/dashboard/my-dicom-wallet/get-more-storage/payments?product=${
                  products.filter((item) => item.name === "Premium Plan")[0]?.id
                }`}
                className='block disabled:opacity-50 disabled:cursor-not-allowed text-white text-sm border border-dicom-primary-100 bg-dicom-primary-100 rounded-lg py-2 w-full text-center'>
                Get started
              </Link> */}
              <button
                disabled
                className='block disabled:opacity-50 disabled:cursor-not-allowed text-white text-sm border border-dicom-primary-100 bg-dicom-primary-100 rounded-lg py-2 w-full text-center'>
                Get started
              </button>
            </div>
            <div className=' py-5 border-r-2  border-gray-300 px-3'>
              {/* <Link
                to={`/dashboard/my-dicom-wallet/get-more-storage/payments?product=${
                  products.filter((item) => item.name === "Professional Plan")[0]?.id
                }`}
                className='block disabled:opacity-50 disabled:cursor-not-allowed text-white text-sm border border-dicom-primary-100 bg-dicom-primary-100 rounded-lg py-2 w-full text-center'>
                Get started
              </Link> */}
              <button
                disabled
                className='block disabled:opacity-50 disabled:cursor-not-allowed text-white text-sm border border-dicom-primary-100 bg-dicom-primary-100 rounded-lg py-2 w-full text-center'>
                Get started
              </button>
            </div>
            <div className=' py-5   px-3'>
              <button
                disabled
                className='block text-white text-sm disabled:opacity-50 disabled:cursor-not-allowed border border-dicom-primary-100 bg-dicom-primary-100 rounded-lg py-2 w-full text-center'>
                Get started
              </button>
            </div>
          </>
          <>
            <div className=''>
              <span className='block text-black text-sm font-semibold py-4'>Cloud Storage of DICOM files</span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm border bg-dicom-primary-100/5  py-4 w-full text-center'>Up to 2 GB</span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm border bg-dicom-primary-100/5  py-4 w-full text-center'>Up to 30 GB</span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm border bg-dicom-primary-100/5  py-4 w-full text-center'>
                Up to 100 GB
              </span>
            </div>
            <div className='  '>
              <span className='block  text-sm border bg-dicom-primary-100/5  py-4 w-full text-center'>
                More than 100 GB
              </span>
            </div>
          </>
          <>
            <div className=''>
              <span className='block text-black text-sm font-semibold py-4'>Maximum Storage Duration</span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm   py-4 w-full text-center'>3 Months</span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm    py-4 w-full text-center'>Unlimited</span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm    py-4 w-full text-center'>Unlimited</span>
            </div>
            <div className='  '>
              <span className='block  text-sm    py-4 w-full text-center'>Customizable</span>
            </div>
          </>
          <>
            <div className=''>
              <span className='block text-black text-sm font-semibold py-4'>Number of Monthly Shares</span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm border bg-dicom-primary-100/5  py-4 w-full text-center'>Unlimited</span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm border bg-dicom-primary-100/5  py-4 w-full text-center'>Unlimited</span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm border bg-dicom-primary-100/5  py-4 w-full text-center'>Unlimited</span>
            </div>
            <div className='  '>
              <span className='block  text-sm border bg-dicom-primary-100/5  py-4 w-full text-center'>
                Customizable
              </span>
            </div>
          </>
          <>
            <div className=''>
              <span className='block text-black text-sm font-semibold py-4'>Anonymization</span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm   py-4 w-full text-center'>Up to 100 studies</span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm    py-4 w-full text-center'>Up to 400 studies</span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm    py-4 w-full text-center'>Up to 1000 studies</span>
            </div>
            <div className='  '>
              <span className='block  text-sm    py-4 w-full text-center'>Customizable</span>
            </div>
          </>
          <>
            <div className=''>
              <span className='block text-black text-sm font-semibold py-2'>
                Chat, Add comment and Attach file to studies
              </span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm border bg-dicom-primary-100/5  py-4 w-full text-center'>
                Up to 100 studies
              </span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm border bg-dicom-primary-100/5  py-4 w-full text-center'>
                Up to 400 studies
              </span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm border bg-dicom-primary-100/5  py-4 w-full text-center'>
                Up to 1000 studies
              </span>
            </div>
            <div className='  '>
              <span className='block  text-sm border bg-dicom-primary-100/5  py-4 w-full text-center'>
                Customizable
              </span>
            </div>
          </>
          <>
            <div className=''>
              <span className='block text-black text-sm font-semibold py-4'>Downloads studies</span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm   py-4 w-full text-center'>Up to 100 studies</span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm    py-4 w-full text-center'>Up to 400 studies</span>
            </div>
            <div className=' border-r-2  border-gray-300 '>
              <span className='block  text-sm    py-4 w-full text-center'>Up to 1000 studies</span>
            </div>
            <div className='  '>
              <span className='block  text-sm    py-4 w-full text-center'>Customizable</span>
            </div>
          </>
        </div>
        <div className='md:hidden'>
          {PricingData.map((item) => (
            <MobileCardSkelliton key={item.title} meta={item} />
          ))}
        </div>
      </section>
    </Layout>
  )
}

const MobileCardSkelliton = ({ meta }) => {
  return (
    <div className='card bg-base-100 shadow-xl my-5 overflow-hidden'>
      <div className='card-body bg-[#f7f8fc]'>
        <div className='items-center flex flex-col gap-5 '>
          <h3 className='text-dicom-text-100 font-semibold '>{meta.title}</h3>
          <div className='w-full mx-auto text-center'>
            <span className=' text-3xl border-l-dicom-on-surface-100 font-bold'>{meta.plan}</span>
            {meta.vat && (
              <span className='block font-semibold text-base text-dicom-text-100'>
                Per Month <span className='text-dicom-on-surface-100 text-xs'>+ VAT</span>
              </span>
            )}
          </div>
          {meta.title == "Basic" ? (
            <Link
              to='/auth/join-us'
              className={`block  text-sm border ${
                meta.title == "Basic" ? "border-dicom-primary-100 text-dicom-primary-100" : ""
              } rounded-lg py-2 w-full text-center`}>
              Get started
            </Link>
          ) : (
            <button
              disabled
              className='block disabled:opacity-50 disabled:cursor-not-allowed text-white text-sm border border-dicom-primary-100 bg-dicom-primary-100 rounded-lg py-2 w-full text-center'>
              Get started
            </button>
          )}
        </div>
      </div>
      <div className='card-body'>
        <ul className='space-y-2'>
          {meta.features.map((item) => (
            <li className='flex gap-2 items-center justify-start leading-tight text-sm'>
              <span>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth={2.5}
                  stroke='currentColor'
                  className='w-4 h-4 text-green-600'>
                  <path strokeLinecap='round' strokeLinejoin='round' d='m4.5 12.75 6 6 9-13.5' />
                </svg>
              </span>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
